<template>
  <div>
    <div>
      <a-form :form="form" :wrapperCol="{xxl:17,xl:15}" :labelCol="{xxl:7,xl:9}" @submit="handleSubmit">
        <a-row gutter="20">
          <a-col
            :xxl="4"
            :xl="6"
            :lg="8">
            <a-form-item
              label="搜索类型">
              <a-select v-decorator="['searchType', { rules: [{ required: true, message: '请选择搜索类型!'}] }]">
                <a-select-option value="real_name">姓名</a-select-option>
                <a-select-option value="phone">手机号</a-select-option>
                <a-select-option value="nickname">昵称</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col
            :xxl="4"
            :xl="6"
            :lg="8">
            <a-form-item
              label="搜索值">
              <a-input v-decorator="['searchValue', { rules: [{ required: true, message: '请输入搜索值!'}] }]"></a-input>
            </a-form-item>
          </a-col>
          <a-col
            :lg="8">
            <a-form-item>
              <a-button type="primary" icon="search" html-type="submit">搜索</a-button>
              <a-button class="margin-left-16" icon="redo" @click="handleReset">重置</a-button>
              <a-button class="margin-left-16" icon="export" @click="exportData">导出数据</a-button>
            </a-form-item>

          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="ant-card">
      <s-table
        ref="table"
        size="small"
        :data="loadData"
        :columns="columns">
        <div slot="head_image" slot-scope="text, record" style="width: 50px;height: 50px;overflow: hidden;border-radius: 25px;text-align: center;margin: 0 auto;">
          <img :src="record.head_image||(record.sex==1?'/images/default_boy.png':'/images/default_girl.png')" style="width: 50px;height: 50px;"/>
        </div>
        <span slot="real_name" slot-scope="text, record">
          {{ record.real_name||"-" }}
        </span>
        <span slot="source_from" slot-scope="text, record">
          {{ record.source_from||"未知" }}
        </span>
        <span slot="sex" slot-scope="text, record">
          {{ record.sex==0?'女':'男' }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="showUserDetail(record.member_id)">明细</a>
        </span>
      </s-table>
    </div>

  </div>
</template>

<script>

import { STable } from '@/components'
import { exportUserData, loadUserList } from '@/api/pc'
import UserDetailInfo from '@/views/user/UserDetailInfo'
export default {
  name: 'WeighRecord',
  components: {
    STable
  },
  data () {
    return {
      form: this.$form.createForm(this),
      columns: [{
        title: '头像',
        dataIndex: 'head_image',
        align: 'center',
        scopedSlots: { customRender: 'head_image' }
      }, {
        title: '姓名',
        dataIndex: 'real_name',
        align: 'center',
        scopedSlots: { customRender: 'real_name' }
      }, {
        title: '昵称',
        dataIndex: 'nickname',
        width: 160,
        align: 'center'
      },
        {
          title: '性别',
          dataIndex: 'sex',
          align: 'center',
          scopedSlots: { customRender: 'sex' }
        },
        {
          title: '身高(cm)',
          dataIndex: 'height',
          align: 'center'
        },
        {
          title: '目标体重(kg)',
          dataIndex: 'goal_weight',
          align: 'center'
        },
        {
          title: '生日',
          dataIndex: 'birthday',
          align: 'center'
        },
        {
          title: '年龄',
          dataIndex: 'age',
          align: 'center'
        },
        {
          title: '客户来源',
          dataIndex: 'source_from',
          align: 'center',
          scopedSlots: { customRender: 'source_from' }
        },
        {
          title: '注册时间',
          dataIndex: 'create_time',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 80,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }],
      queryParams: {},
      loadData: parameter => {
        return loadUserList(Object.assign(this.queryParams, parameter)).then(res => {
          return res.result
        })
      }
    }
  },
  methods: {
    showUserDetail (member) {
      this.showModal(UserDetailInfo, '用户明细', 900, 600, { member: member })
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.queryParams = values
          this.$refs.table.refresh(true)
        }
      })
    },
    handleReset () {
      this.form.resetFields()
      this.queryParams = {}
      this.$refs.table.refresh(true)
    },
    exportData () {
      exportUserData(this.queryParams).then(res => {
         this.saveResultAsExcel(res, '用户列表.xlsx')
      })
    }
  }
}
</script>

<style scoped>

</style>
